<template>
  <div class="setting">
    <h1>请设置抽奖人数</h1>
    <div class="field">
      <div class="control">
        <input v-model="numbers" />
      </div>
    </div>
    <h4>请设置一等奖人数</h4>
    <div class="field">
      <div class="control">
        <input v-model="level1" />
      </div>
    </div>
    <h4>请设置二等奖人数</h4>
    <div class="field">
      <div class="control">
        <input v-model="level2" />
      </div>
    </div>
    <h4>请设置三等奖人数</h4>
    <div class="field">
      <div class="control">
        <input v-model="level3" />
      </div>
    </div>
    <h4>请设置四等奖人数</h4>
    <div class="field">
      <div class="control">
        <input v-model="level4" />
      </div>
    </div>
    <h4>请设置五等奖人数</h4>
    <div class="field">
      <div class="control">
        <input v-model="level5" />
      </div>
    </div>
    <h4>请设置阳光普照人数</h4>
    <div class="field">
      <div class="control">
        <input v-model="level6" />
      </div>
    </div>
    <div class="field is-grouped">
      <div class="control">
        <button class="button is-primary" @click="save">保存</button>
      </div>
      <div class="control">
        <button class="button" @click="clear">清空抽奖历史</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      numbers: 56,
      level1: 1,
      level2: 2,
      level3: 3,
      level4: 6,
      level5: 15,
      level6: 29,
    }
  },
  methods: {
    save () {
      localStorage.setItem('numbers', this.numbers)
      localStorage.setItem('level1', this.level1)
      localStorage.setItem('level2', this.level2)
      localStorage.setItem('level3', this.level3)
      localStorage.setItem('level4', this.level4)
      localStorage.setItem('level5', this.level5)
      localStorage.setItem('level6', this.level6)
      alert('已保存')
    },
    clear () {
      localStorage.setItem('history', '')
      alert('已清空')
    }
  },
  mounted () {
    this.numbers = localStorage.getItem('numbers')
    if (!this.numbers) {
      this.numbers = 56
      localStorage.setItem('numbers', this.numbers)
    }
    this.level1 = localStorage.getItem('level1')
    if (!this.level1) {
      this.level1 = 1
      localStorage.setItem('level1', this.level1)
    }
    this.level2 = localStorage.getItem('level2')
    if (!this.level2) {
      this.level2 = 2
      localStorage.setItem('level2', this.level2)
    }
    this.level3 = localStorage.getItem('level3')
    if (!this.level3) {
      this.level3 = 3
      localStorage.setItem('level3', this.level3)
    }
    this.level4 = localStorage.getItem('level4')
    if (!this.level4) {
      this.level4 = 6
      localStorage.setItem('level4', this.level4)
    }
    this.level5 = localStorage.getItem('level5')
    if (!this.level5) {
      this.level5 = 15
      localStorage.setItem('level5', this.level5)
    }
    this.level6 = localStorage.getItem('level6')
    if (!this.level6) {
      this.level6 = 29
      localStorage.setItem('level6', this.level6)
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 30px;
  margin-bottom: 6px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.setting {
  text-align: left;
  width: 50%;
}

</style>
