<template>
  <div class="lottery">
    <div class="current">{{ current }}</div>
    <button class="button is-large is-warning" v-if="!timer" @click="start">开始</button>
    <button class="button is-large is-danger" v-if="timer" @click="stop">停</button>

<!--    <table style="position: fixed;top: 20px;left: 20px">-->
<!--      <tr>-->
<!--        <td>1</td>-->
<!--        <td>{{this.level1}}</td>-->
<!--        <td>{{rewardLeft(1)}}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>2</td>-->
<!--        <td>{{this.level2}}</td>-->
<!--        <td>{{rewardLeft(2)}}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>3</td>-->
<!--        <td>{{this.level3}}</td>-->
<!--        <td>{{rewardLeft(3)}}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>4</td>-->
<!--        <td>{{this.level4}}</td>-->
<!--        <td>{{rewardLeft(4)}}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>5</td>-->
<!--        <td>{{this.level5}}</td>-->
<!--        <td>{{rewardLeft(5)}}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>6</td>-->
<!--        <td>{{this.level6}}</td>-->
<!--        <td>{{rewardLeft(6)}}</td>-->
<!--      </tr>-->
<!--    </table>-->

    <div class="result" >
      <table class="table" v-if="history.length > 0">
        <thead>
        <tr>
          <th>轮次</th>
          <th>中奖号码</th>
          <th>奖项</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in history" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.split('-')[0] }}</td>
          <td>{{ displayReward(item.split('-')[1]) }}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      numbers: [],
      current: '',
      timer: null,
      history: [

      ],
      level1: 1,
      level2: 2,
      level3: 3,
      level4: 6,
      level5: 15,
      level6: 29,
    }
  },
  computed: {
    neverWin () {
      var pools = [];
      for (var i = 1; i <= this.numbers; i++) {
        pools.push(i);
      }
      return pools.filter((item) => !this.history.some((historyItem) => historyItem.split('-')[0] == item))
    }
  },
  methods: {
    random (minNum, maxNum) {
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum)
    },
    randomReward () {

      var used_level1 = 0;
      var used_level2 = 0;
      var used_level3 = 0;
      var used_level4 = 0;
      var used_level5 = 0;
      var used_level6 = 0;

      this.history.forEach(history => {
        var array = history.split('-');
        if (array[1] == 1) {
          used_level1++
        } else if (array[1] == 2) {
          used_level2++
        } else if (array[1] == 3) {
          used_level3++
        } else if (array[1] == 4) {
          used_level4++
        } else if (array[1] == 5) {
          used_level5++
        } else if (array[1] == 6) {
          used_level6++
        }
      })

      var pools = [];
      if (this.history.length >= 20) {
        for (var i = 0; i < this.level1 - used_level1; i++) {
          pools.push(1);
        }
      }
      for (var i2 = 0; i2 < this.level2 - used_level2; i2++) {
        pools.push(2);
      }
      for (var i3 = 0; i3 < this.level3 - used_level3; i3++) {
        pools.push(3);
      }
      for (var i4 = 0; i4 < this.level4 - used_level4; i4++) {
        pools.push(4);
      }
      for (var i5 = 0; i5 < this.level5 - used_level5; i5++) {
        pools.push(5);
      }
      for (var i6 = 0; i6 < this.level6 - used_level6; i6++) {
        pools.push(6);
      }

      var random = this.random(0, pools.length - 1);
      return pools[random];
    },
    start () {
      if (this.neverWin.length < 1) {
        alert('所有人都已中过奖! 请到设置清空中奖纪录!')
        return
      }
      this.timer = setInterval(this.refresh, 100)
    },
    stop () {
      clearInterval(this.timer)
      this.timer = null
      this.saveHistory()
    },
    displayReward(reward) {
      if (reward == 1) {
        return "一等奖";
      } else if (reward == 2) {
        return "二等奖";
      } else if (reward == 3) {
        return "三等奖";
      } else if (reward == 4) {
        return "四等奖";
      } else if (reward == 5) {
        return "五等奖";
      } else {
        return "阳光普照奖";
      }
    },
    rewardLeft(reward) {

      var left1 = this.level1 - this.history.filter((item) => item.split('-')[1] == 1).length;
      var left2 = this.level2 - this.history.filter((item) => item.split('-')[1] == 2).length;
      var left3 = this.level3 - this.history.filter((item) => item.split('-')[1] == 3).length;
      var left4 = this.level4 - this.history.filter((item) => item.split('-')[1] == 4).length;
      var left5 = this.level5 - this.history.filter((item) => item.split('-')[1] == 5).length;
      var left6 = this.level6 - this.history.filter((item) => item.split('-')[1] == 6).length;

      if (reward == 1) {
        return left1;
      } else if (reward == 2) {
        return left2;
      } else if (reward == 3) {
        return left3;
      } else if (reward == 4) {
        return left4;
      } else if (reward == 5) {
        return left5;
      } else {
        return left6;
      }
    },
    refresh () {
      this.current = this.neverWin[this.random(0, this.neverWin.length - 1)]
    },
    saveHistory () {
      this.history.push(this.current.toString() + '-' + this.randomReward())
      localStorage.setItem('history', JSON.stringify(this.history))
    },
    readNumbers () {
      const numStr = localStorage.getItem("numbers")
      if (numStr) this.numbers = numStr
      if (this.numbers.length === 0) {
        alert('抽奖池为空! 请到右上角设置中添加!')
        this.numbers = 56
      }
    },
    readRewards () {
      const level1 = localStorage.getItem("level1")
      if (level1) this.level1 = level1
      const level2 = localStorage.getItem("level2")
      if (level2) this.level2 = level2
      const level3 = localStorage.getItem("level3")
      if (level3) this.level3 = level3
      const level4 = localStorage.getItem("level4")
      if (level4) this.level4 = level4
      const level5 = localStorage.getItem("level5")
      if (level5) this.level5 = level5
      const level6 = localStorage.getItem("level6")
      if (level6) this.level6 = level6
    },
    readHistory () {
      const history = localStorage.getItem("history")
      if (history) this.history = JSON.parse(history)
    }
  },
  mounted () {
    this.readNumbers()
    this.readRewards()
    this.readHistory()
    this.current = this.numbers[0].split('').map(() => '*').join('')
  }
}
</script>

<style scoped>
h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
}

.current {
  font-size: 200px;
  margin-bottom: 50px;
}

.button.is-large {
  width: 100px;
}

.table {

  background-color: transparent;
  color: #ffffff;;
}

.table thead td, .table thead th {
  color: #ffffff;
}

.result {
  position: fixed;
  left: 20px;
  bottom: 20px;
  overflow: auto;
  height: 600px;
}
</style>
